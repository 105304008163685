import React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useStyles from './StaffMontior.styles';
import { gql, useSubscription } from '@apollo/client';
import LoadingIndicator from '@/components/core/LoadingIndicator';
import { formatDuration } from '@/util/duration.jsx';
import useNow from '@/hooks/useNow';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const TitleRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderColor: theme.palette.text.secondary,
    },
  })
)(TableRow);

const TitleCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
    },
  })
)(TableCell);

interface MemberTableRowProps extends Entry {
  compact?: boolean;
}

interface DurationProps {
  since: string;
}

const Duration = ({ since }: DurationProps) => {
  const now = useNow();
  return <>{formatDuration(since, now)}</>;
};

const MemberTableRow = ({ name, room, roomUpdatedAt, compact }: MemberTableRowProps) => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCell size={compact ? 'small' : 'medium'}>
        <Typography variant="h3">{name}</Typography>
      </TableCell>
      <TableCell size={compact ? 'small' : 'medium'}>
        <Typography
          variant="h3"
          className={clsx(classes.locationIn, {
            [classes.inOr]: room.type === 'OR' && !compact,
          })}
        >
          {room.name}
        </Typography>
      </TableCell>
      <TableCell size={compact ? 'small' : 'medium'}>
        <Box className={classes.elapsedTime} px={1} py={0.5} display="flex" alignItems="center">
          <Typography variant="h6" component="span" className={classes.elapsedTime}>
            <Duration since={roomUpdatedAt} />
          </Typography>
        </Box>
      </TableCell>
    </StyledTableRow>
  );
};

interface Entry {
  id: any;
  name: string;
  room: {
    id: any;
    name: string;
    type: string;
  };
  roomUpdatedAt: string;
}

interface TableSectionProps {
  title?: string;
  entries: Entry[];
  compact?: boolean;
}

const TableSection = ({ title, entries, compact }: TableSectionProps) => (
  <>
    {title && (
      <TitleRow>
        <TitleCell colSpan={5}>
          <Typography variant="h6">{title}</Typography>
        </TitleCell>
      </TitleRow>
    )}
    {entries.map((member, index) => (
      <MemberTableRow key={index} {...member} compact={compact} />
    ))}
  </>
);

const StaffMonitor = () => {
  const { loading, error, data } = useSubscription(gql`
    subscription staffMonitoring {
      staffMonitoring {
        physicians {
          id
          name
          room {
            id
            name
            type
          }
          roomUpdatedAt
        }
        staffMembers {
          id
          name
          room {
            id
            name
            type
          }
          roomUpdatedAt
        }
        anesthesiologists {
          id
          name
          room {
            id
            name
            type
          }
          roomUpdatedAt
        }
      }
    }
  `);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <pre>Error: {String(error)}</pre>;
  }

  const { physicians, staffMembers, anesthesiologists } = data.staffMonitoring;

  return (
    <Box p={4}>
      <Table>
        <colgroup>
          <col style={{ width: '100%' }} />
          <col style={{ width: '20ch' }} />
          <col style={{ width: '0' }} />
          <col style={{ minWidth: '20ch' }} />
          <col style={{ minWidth: '18ch' }} />
        </colgroup>
        <TableHead>
          <TitleRow>
            <TableCell>
              <Typography variant="h6">Staff Members</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Room</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Time</Typography>
            </TableCell>
          </TitleRow>
        </TableHead>
        <TableBody>
          <TableSection title="Physician" entries={physicians} />
          <TableSection title="Staff" entries={staffMembers} compact />
          <TableSection title="Anesthesiologist" entries={anesthesiologists} compact />
        </TableBody>
      </Table>
    </Box>
  );
};

export default StaffMonitor;
